// Entry point for the build script in your package.json

// import { init as initApm } from '@elastic/apm-rum'
import "@hotwired/turbo-rails";
import "./controllers";
import * as bootstrap from "bootstrap";

import posthog from "posthog-js";

posthog.init("phc_fcV8NpCh7tvp836e2Hw4VVa32EPIq4G9IE3KH1IepMJ", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
});

// Turbo.session.drive = false

// Turbo.setProgressBarDelay(1)

import jquery from "jquery";
import cookie from "jquery.cookie";

window.jQuery = jquery;
window.$ = jquery;

/*
var apm = initApm({
  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: 'FavQs',

  // Set custom APM Server URL (default: http://localhost:8200)
  serverUrl: 'https://71c18abde34c46f3ad518c23228aff82.apm.us-east-1.aws.cloud.es.io:443',

  // Set the service version (required for source map feature)
  //serviceVersion: '',

  // Set the service environment
  environment: 'production'
})
*/

function delay(callback, ms) {
  var timer = 0;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      callback.apply(context, args);
    }, ms || 0);
  };
}

document.addEventListener("turbo:load", function () {
  $("#search").focus();

  if (window.location.pathname.startsWith("/search/")) {
    var param = window.location.pathname.split("/").pop();
    $("#search").val(unescape(param));
    $("body").addClass("active-search");
    $(".loading-icon").css("display", "block");
    $.get({
      url: "/search/" + $("#search").val(),
      type: "get",
      data: "",
      success: function (data) {
        var state = { q: $("#search").val() };
        var title = "FavQs: " + $("#search").val();
        var url = "/search/" + $("#search").val();
        history.pushState(state, title, url);
        $(".loading-icon").css("display", "none");
      },
      error: function (data) {
        $(".loading-icon").css("display", "none");
      },
    });
    $(".chat").css("display", "none");
  }

  $(".hero-search #search").on(
    "keyup",
    delay(function () {
      if ($("#search").val().length >= 2) {
        $("body").addClass("active-search");
        $(".loading-icon").css("display", "block");
        $(".search-ui-container").css("opacity", "0.3");
        $.get({
          url: "/search/" + $("#search").val(),
          type: "get",
          data: "",
          success: function (data) {
            var state = { q: $("#search").val() };
            var title = "FavQs: " + $("#search").val();
            var url = "/search/" + $("#search").val();
            history.pushState(state, title, url);
            $(".loading-icon").css("display", "none");
            $(".search-ui-container").css("opacity", "1");
          },
          error: function (data) {
            $(".loading-icon").css("display", "none");
            $(".search-ui-container").css("opacity", "1");
          },
        });
        $(".chat").css("display", "none");
      } else {
        history.pushState({}, "FavQs", "/");
        $("body").removeClass("active-search");
        $(".chat").css("display", "block");
        $(".ots").css("display", "none");
        $(".loading-icon").css("display", "none");
        $(".search-ui-container").css("opacity", "1");
      }
    }, 100),
  );

  checkDialogueLineLink();

  $("a.fav i").hover(
    function () {
      if ($(this).hasClass("bi-heart-fill")) {
        $(this).addClass("bi-heart bi-heart-hover");
        $(this).removeClass("bi-heart-fill");
      }
    },
    function () {
      if ($(this).hasClass("bi-heart-hover")) {
        $(this).addClass("bi-heart-fill");
        $(this).removeClass("bi-heart bi-heart-hover");
      }
    },
  );
});

$(document).on("click", ".logo", function (event) {
  $(".logo").animate(
    {
      top: "+=1px",
    },
    1,
    null,
  );
});

$(document).on("click", ".message-send", function (event) {
  $(".message-status").html(
    'Sending... &nbsp;<i class="fa bi-rotate-right bi-spin" style="color: #666;"></i>',
  );
  $(".message-status").show();
});

$(document).on("click", "#add-quote-optional-link", function (event) {
  if ($("#add-quote-optional").is(":hidden")) {
    $("#add-quote-optional-link").html(
      "<span class='bi bi-chevron-down'></span> Optional",
    );
  } else {
    $("#add-quote-optional-link").html(
      "<span class='bi bi-chevron-right'></span> Optional",
    );
  }
  $("#add-quote-optional").toggle();
});

function checkDialogueLineLink() {
  if ($("#dialogue-lines .row").length == 0) {
    return;
  }
  var i = $("#dialogue-lines .row").length;
  if (i > 4) {
    $("#remove-dialogue-line-link").show();
  }
}

$(document).on("click", "#add-dialogue-line-link", function (event) {
  var i = ($("#dialogue-lines .row").length / 2 + 1).toString();
  if (i <= 19) {
    $("#dialogue-lines").append(
      '<div class="row"><div class="form-group"><label class="col-md-2 control-label" for="authorInput' +
        i +
        '">Speaker ' +
        i +
        ':</label><div class="col-md-2"><input class="form-control" id="authorInput' +
        i +
        '" name="dialogue[author' +
        i +
        ']" type="text"></div></div></div><div class="row"><div class="form-group"><label class="col-md-2 control-label" for="quoteInput' +
        i +
        '"> Quote ' +
        i +
        ':</label><div class="col-md-4"><input class="form-control" id="quoteInput' +
        i +
        '" name="dialogue[body' +
        i +
        ']" type="text"></div></div></div>',
    );
  }
  if (i == 19) {
    $("#add-dialogue-line-link").hide();
  } else {
    $("#remove-dialogue-line-link").show();
  }
});

$(document).on("click", "#remove-dialogue-line-link", function (event) {
  if ($("#dialogue-lines .row").length > 4) {
    $("#dialogue-lines .row:last").remove();
    $("#dialogue-lines .row:last").remove();
  }
  if ($("#dialogue-lines .row").length == 4) {
    $("#remove-dialogue-line-link").hide();
  } else {
    $("#add-dialogue-line-link").show();
  }
});
